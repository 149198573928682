<template>
  <div>
    <div class="bgBox">
      <el-carousel arrow="never" indicator-position="none">
        <el-carousel-item v-for="img in bglist" :key="img.id">
          <img class="img" :src="img.url" alt="" />
        </el-carousel-item>
      </el-carousel>
      <div class="bgTitle">
        <div>软硬件一体化综合解决方案高效、专业、轻松的管理方式</div>
      </div>
      <div class="bgLeftTitle">
        <div>提供高效的定制化管理解决方案</div>
        <router-link to="/softwarecustomization">
          <img
            src="@/assets/solution/bgleft.png"
            alt=""
          />个性定制化服务>></router-link
        >
      </div>
    </div>
    <div class="mainBox">
      <div class="mainauto">
        <div class="cardBox" id="zhsb">
          <img src="@/assets/solution/fangan1.png" alt="" />

          <div class="cardTitle">智慧设备运维平台</div>
          <div class="cardMain">
            <span>设备维修与保养的结合</span
            ><router-link to="/zhsbSolution">了解详情>></router-link>
          </div>
        </div>
        <div class="cardBox" id="zhcc">
          <img src="@/assets/solution/fangan2.png" alt="" />

          <div class="cardTitle">智慧仓储物资平台</div>
          <div class="cardMain">
            <span>应用管理的基本原理和科学方法进行管理 </span
            ><router-link to="/zhccmanage">了解详情>></router-link>
          </div>
        </div>
        <div class="cardBox" id="zhxy">
          <img src="@/assets/solution/fangan3.png" alt="" />

          <div class="cardTitle">智慧校园平台</div>
          <div class="cardMain">
            <span>以智慧课堂为核心,以智慧管理为支撑 </span
            ><router-link to="/zhxymanage">了解详情>></router-link>
          </div>
        </div>
        <div class="cardBox" id="zhyq">
          <img src="@/assets/solution/fangan4.png" alt="" />

          <div class="cardTitle">智慧园区平台</div>
          <div class="cardMain">
            <span>整体解决方案和持续创新的运营模式 </span
            ><router-link to="/zhyqsolution">了解详情>></router-link>
          </div>
        </div>
        <div class="cardBox" id="zhwy">
          <img src="@/assets/solution/fangan5.png" alt="" />

          <div class="cardTitle">智慧物业平台</div>
          <div class="cardMain">
            <span>新的商业模式、服务和管理物业服务企业 </span
            ><router-link to="/zhwysolution">了解详情>></router-link>
          </div>
        </div>
        <div class="cardBox" id="zhsys">
          <img src="@/assets/solution/fangan6.png" alt="" />

          <div class="cardTitle">智慧实验室平台</div>
          <div class="cardMain">
            <span>使用方便,功能强大,安全可靠 </span
            ><router-link to="/zhsyssolution">了解详情>></router-link>
          </div>
        </div>
        <div class="cardBox" id="sbyw">
          <img src="@/assets/solution/fangan7.png" alt="" />

          <div class="cardTitle">设备运维管控平台</div>
          <div class="cardMain">
            <span>保持设备稳定性，实现企业效益的全面提升 </span
            ><router-link to="/sbywsolution">了解详情>></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "total-solution",

  data() {
    return {
      title: "",
      bglist: [{ id: 0, url: require("@/assets/solution/bg.png") }],
    };
  },
  created() {
    //创建时执行跳转锚点位置
    this.$nextTick(() => {
      this.getlocal();
    });
  },
  methods: {
    //从我本地找到id
    getlocal() {
      //找到锚点id
      let selectId = localStorage.getItem("toId");
      let toElement = document.getElementById(selectId);
      console.log(toElement);
      toElement.classList.add("fadeIn");
      //如果对应id存在，就跳转
      if (selectId) {
        toElement.scrollIntoView(true);
      }
    },
  },
  //离开页面进行对localStorage  id销毁，避免其他入口进来有锚点问题
  destroyed() {
    localStorage.setItem("toId", "");
  },
};
</script>

<style scoped>
.bgBox {
  width: 100%;
  height: 700px;
  position: relative;
}
.bgBox >>> .el-carousel__container {
  height: 700px;
}
.bgTitle {
  min-width: 700px;
  position: absolute;
  color: #fff;
  left: 50%;
  top: 50%;
  padding: 30px 0;
  transform: translate(-50%, -50%);
  z-index: 9;
  line-height: 50px;
  background: url("../assets/solution/title_bg.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
  font-size: 20px;
}
.bgLeftTitle {
  min-width: 700px;
  position: absolute;
  color: #fff;
  left: 128px;
  bottom: 175px;
  z-index: 9;
  line-height: 50px;
}
.bgLeftTitle > div:nth-child(1) {
  font-size: 14px;
}
.bgLeftTitle a {
  display: block;
  width: 188px;
  height: 40px;
  font-size: 16px;
  border-radius: 6px;
  border: 1px solid #fff;
  text-align: center;
  line-height: 40px;
}
.bgLeftTitle a img {
  width: 20px;
  height: 26px;
  vertical-align: middle;
  margin-right:6px;
  margin-bottom: 4px;
}
.img {
  width: 100%;
  height: 100%;
}
.mainBox {
  width: 100%;
  background-color: #f7f9fc;
  padding-bottom: 194px;
  background: url("../assets/index/bottom_bg.png") no-repeat bottom;
  background-size: 100% 10%;
}
.mainauto {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}
.cardBox {
  width: 368px;
  height: 322px;
  margin-top: 56px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  line-height: 30px;
  margin-right: 48px;
}
.cardBox:nth-child(3n) {
  margin-right: 0;
}
.cardBox img {
  width: 100%;
  height: 238px;
}

.cardTitle {
  color: #333;
  font-size: 18px;
  padding-left: 10px;
  font-weight: bold;
}
.cardMain {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
  padding: 0 10px;
}
.cardMain a {
  color: #5590ec;
}
.fadeIn {
  animation: fadeIn 1s ease 0s both;
}
@keyframes fadeIn {
  0% {
    margin-top: 56px;
  }
  25% {
    margin-top: 7px;
  }
  50% {
    margin-top: 56px;
  }
  75% {
    margin-top: 7px;
  }
  100% {
    margin-top: 56px;
  }
}
</style>